<template>
<v-container fluid>
  <v-layout row wrap :class="{'pa-1':$vuetify.breakpoint.xsOnly,
                              'pa-2':$vuetify.breakpoint.smOnly,
                              'pa-3':$vuetify.breakpoint.mdOnly,
                              'pa-4':$vuetify.breakpoint.lgOnly,
                              'pa-5':$vuetify.breakpoint.xlOnly}">
    <v-flex xs12 sm10 lg8 align-center justify-center>
      <v-card flat :elevation="$vuetify.breakpoint.xsOnly?0:3" v-if="!sent">
        <v-container fluid>
          <v-layout row wrap class="mb-3">
            <v-flex>
              <h1 class="starling-h1">{{$t('app.feedbacks.title')}}</h1>
            </v-flex>
          </v-layout>
          <v-layout row wrap>
            <v-flex xs12>
              <v-form
                      ref="form">
                <v-textarea
                        v-model="feedback"
                        :counter="1024"
                        :label="$t('app.feedbacks.field')"
                        outline
                        auto-grow
                        :rules="required">
                </v-textarea>
              </v-form>
            </v-flex>
          </v-layout>
        </v-container>
        <v-card-actions>
          <v-layout row wrap>
            <v-flex xs6 lg4>
              <primary-button
                      class="mt-5 mb-5"
                      @click="submitFeedback()"
                      :loading="loading"
                      :disabled="!feedback.length"
                      block
                      large>
                {{$t('common.actions.send')}}
              </primary-button>
            </v-flex>
          </v-layout>
        </v-card-actions>
      </v-card>
      <v-card flat :elevation="$vuetify.breakpoint.xsOnly?0:3" v-if="sent">
        <v-layout row wrap class="mb-3" justify-center align-center>
          <v-flex xs12 class="text-center">
            <feedback-sent-image></feedback-sent-image>
          </v-flex>
          <v-flex xs12 class="text-center">
            <h1 class="starling-h1">{{$t('app.feedbacks.success.message')}}</h1>
          </v-flex>
          <v-flex xs6>
            <primary-button
                    class="mt-5 mb-5"
                    @click="backToProfile()"
                    block
                    large>
              {{$t('common.actions.back')}}
            </primary-button>
          </v-flex>
        </v-layout>
      </v-card>
    </v-flex>
  </v-layout>
</v-container>
</template>

<script>
import FeedbackSentImage from './partials/feedback-sent-image';
import PrimaryButton from '@/views/components/button/primary-button';

export default {
  name: 'feedbackSettings',
  components: { PrimaryButton, FeedbackSentImage },
  data() {
    return {
      sent: false,
      feedback: '',
      required: [
        v => v.length <= 1024 || this.$t('common.errors.max1024'),
      ],
      loading: false,
    };
  },
  methods: {
    backToProfile() {
      this.$router.push({ name: 'profile' });
    },
    submitFeedback() {
      const result = this.$refs.form.validate();
      if (result) {
        this.loading = true;
        this.$store.dispatch('submitFeedback', this.feedback).then(() => {
          this.sent = true;
          this.loading = false;
        });
      }
    },
  },
};
</script>
