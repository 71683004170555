var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-layout",
        {
          class: {
            "pa-1": _vm.$vuetify.breakpoint.xsOnly,
            "pa-2": _vm.$vuetify.breakpoint.smOnly,
            "pa-3": _vm.$vuetify.breakpoint.mdOnly,
            "pa-4": _vm.$vuetify.breakpoint.lgOnly,
            "pa-5": _vm.$vuetify.breakpoint.xlOnly
          },
          attrs: { row: "", wrap: "" }
        },
        [
          _c(
            "v-flex",
            {
              attrs: {
                xs12: "",
                sm10: "",
                lg8: "",
                "align-center": "",
                "justify-center": ""
              }
            },
            [
              !_vm.sent
                ? _c(
                    "v-card",
                    {
                      attrs: {
                        flat: "",
                        elevation: _vm.$vuetify.breakpoint.xsOnly ? 0 : 3
                      }
                    },
                    [
                      _c(
                        "v-container",
                        { attrs: { fluid: "" } },
                        [
                          _c(
                            "v-layout",
                            {
                              staticClass: "mb-3",
                              attrs: { row: "", wrap: "" }
                            },
                            [
                              _c("v-flex", [
                                _c("h1", { staticClass: "starling-h1" }, [
                                  _vm._v(_vm._s(_vm.$t("app.feedbacks.title")))
                                ])
                              ])
                            ],
                            1
                          ),
                          _c(
                            "v-layout",
                            { attrs: { row: "", wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs12: "" } },
                                [
                                  _c(
                                    "v-form",
                                    { ref: "form" },
                                    [
                                      _c("v-textarea", {
                                        attrs: {
                                          counter: 1024,
                                          label: _vm.$t("app.feedbacks.field"),
                                          outline: "",
                                          "auto-grow": "",
                                          rules: _vm.required
                                        },
                                        model: {
                                          value: _vm.feedback,
                                          callback: function($$v) {
                                            _vm.feedback = $$v
                                          },
                                          expression: "feedback"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-card-actions",
                        [
                          _c(
                            "v-layout",
                            { attrs: { row: "", wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs6: "", lg4: "" } },
                                [
                                  _c(
                                    "primary-button",
                                    {
                                      staticClass: "mt-5 mb-5",
                                      attrs: {
                                        loading: _vm.loading,
                                        disabled: !_vm.feedback.length,
                                        block: "",
                                        large: ""
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.submitFeedback()
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.$t("common.actions.send")
                                          ) +
                                          "\n              "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.sent
                ? _c(
                    "v-card",
                    {
                      attrs: {
                        flat: "",
                        elevation: _vm.$vuetify.breakpoint.xsOnly ? 0 : 3
                      }
                    },
                    [
                      _c(
                        "v-layout",
                        {
                          staticClass: "mb-3",
                          attrs: {
                            row: "",
                            wrap: "",
                            "justify-center": "",
                            "align-center": ""
                          }
                        },
                        [
                          _c(
                            "v-flex",
                            { staticClass: "text-center", attrs: { xs12: "" } },
                            [_c("feedback-sent-image")],
                            1
                          ),
                          _c(
                            "v-flex",
                            { staticClass: "text-center", attrs: { xs12: "" } },
                            [
                              _c("h1", { staticClass: "starling-h1" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("app.feedbacks.success.message")
                                  )
                                )
                              ])
                            ]
                          ),
                          _c(
                            "v-flex",
                            { attrs: { xs6: "" } },
                            [
                              _c(
                                "primary-button",
                                {
                                  staticClass: "mt-5 mb-5",
                                  attrs: { block: "", large: "" },
                                  on: {
                                    click: function($event) {
                                      return _vm.backToProfile()
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("common.actions.back")) +
                                      "\n            "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }